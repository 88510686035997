<template>
  <div id="select-work-day">
    <van-picker show-toolbar :title="title" :columns="columns" @confirm="getConfirmPickerObj" :default-index="ini" />
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: "",
    },
    columns: {
      type: Array,
      default() {
        return [];
      },
    },
    ini: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      selected: "",
    };
  },
  methods: {
    getConfirmPickerObj(e) {
      console.log(this.selected);
      let result_obj = {
        show: false,
        cn: e,
      };
      this.$emit("get", result_obj);
    },
  },
};
</script>

<style>
</style>