<template>
  <div id="serverAdress">
    <tool-bar leftTitle="取消" rightTitle="确定" title="服务区域选择" @cancel="cancel" @confirm="submitAdress" />
    <div class="selected">
      <span class="provinces" @click="resetProvinces" v-if="province_list.find(item => item.id == selected_province)">{{province_list.find(item => item.id == selected_province).name}}</span>
      <span class="city" @click="resetCity" v-if="city_list.find(item => item.id == selected_city)">{{city_list.find(item => item.id == selected_city).name}}</span>
      <!-- <span class="district">{{[].push(selected_county.forEach(item => {county_list.find(k => k.id == item)}))}}</span> -->
    </div>
    <div style="height: 4rem">
      <van-notice-bar left-icon="volume-o" text="服务区域必选1个，优先服务区域可选2个，普通服务区域可多选" />
    </div>
    <div class="adressWrap" v-if="wrapShow == 1">
      <div class="leftWrap">
        <van-radio-group v-model="selected_province">
          <div class="publicItem" v-for="(item) in province_list" :key="item.id" @click="selectProvince(item)">
            <van-radio :name="item.id">
              <template #icon="props">
                <svg class="iconResult" aria-hidden="true" v-if="props.checked">
                  <use xlink:href="#icon-feijifenxianglianjie"></use>
                </svg>
              </template>
              {{item.name}}
            </van-radio>
          </div>
        </van-radio-group>
      </div>
      <div class="rightWrap">
        <van-radio-group v-model="selected_city">
          <div class="publicItem" v-for="(item) in city_list" :key="item.id" @click="selectCity(item)">
            <van-radio :name="item.id">
              <template #icon="props">
                <svg class="iconResult" aria-hidden="true" v-if="props.checked">
                  <use xlink:href="#icon-feijifenxianglianjie"></use>
                </svg>
              </template>
              {{item.name}}
            </van-radio>
          </div>
        </van-radio-group>
      </div>
    </div>
    <div class="adressWrap" v-if="wrapShow == 2">
      <div class="leftWrap">
        <div class="publicItem" v-for="(item,index) in serverAdress" :key="index" @click="selectCounty(item,index)" :class="selectRangeTemp == index? 'act': ''">
          <span>{{item.type}}</span>
        </div>
      </div>
      <div class="rightWrap">
        <van-checkbox-group v-model="selected_county">
          <van-checkbox v-for="(k,i) in county_list" :key="i" checked-color="#FF816C" class="selectDistrict" :name="k.id">{{k.name}}
            <template #icon="props">
              <svg class="iconResult" aria-hidden="true" v-if="props.checked">
                <use xlink:href="#icon-feijifenxianglianjie"></use>
              </svg>
            </template>
          </van-checkbox>
        </van-checkbox-group>
      </div>
    </div>
  </div>
</template>
<script>
import ToolBar from "@/components/effect/ToolBar.vue";

export default {
  props: {
    modelEmitObj: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      province_list: [],
      city_list: [],
      county_list: [],
      selected_province: undefined,
      selected_city: undefined,
      selected_county: [],
      result: [],
      districtList: [],
      selectRangeTemp: 0,
      // ---
      wrapShow: 1,
      serverAdress: [
        {
          id: 0,
          type: "重点服务区域",
        },
        // {
        //   id: 1,
        //   type: "优先服务区域",
        // },
        // {
        //   id: 2,
        //   type: "普通服务区域",
        // },
      ],
    };
  },

  components: {
    ToolBar,
  },
  created() {
    this.getAllAddress();
    setTimeout(() => {
      this.addressCopy();
    }, 1000);
  },
  methods: {
    /**
     * 获取全国地址库
     */
    getAllAddress() {
      this.$http
        .get("https://cdn.yishifu.cn/static/json/addresses.json")
        .then((res) => {
          console.log(res, "总地址");
          this.province_list = res.data;
        });
    },
    /**
     * 记忆拷贝
     */
    addressCopy() {
      console.log(this.modelEmitObj, 666);
      this.selected_province = this.modelEmitObj.selected_province;
      this.selected_city = this.modelEmitObj.selected_city;
      this.selected_county = this.modelEmitObj.selected_county;

      console.log(
        (this.city_list = this.province_list.find(
          (option) => option.id == this.modelEmitObj.selected_province
        ).childs)
      );
      this.wrapShow = 2;
      this.county_list = this.city_list.find(
        (option) => option.id == this.modelEmitObj.selected_city
      ).childs;
    },
    selectProvince(item) {
      console.log(item);
      this.city_list = item.childs;
    },
    selectCity(item) {
      this.county_list = item.childs;
      this.wrapShow = 2;
    },
    selectCounty(item) {
      console.log(item);
    },
    resetProvinces() {
      this.wrapShow = 1;
      this.selected_county = [];
    },
    resetCity() {
      this.wrapShow = 1;
      this.selected_county = [];
    },
    submitAdress() {
      let selected_county_code = this.selected_county.join(",");
      let _temp_arr = [];
      this.selected_county.forEach((el) => {
        if (this.county_list.find((item) => item.id == el).name) {
          _temp_arr.push(this.county_list.find((item) => item.id == el).name);
        }
      });
      console.log("区的中文", _temp_arr);
      let selected_county_cn = _temp_arr.join(",");
      let selected_address_obj = [
        this.selected_province,
        this.selected_city,
        this.selected_county,
      ];
      this.$emit("get", {
        show: false,
        cn: selected_county_cn,
        id: this.selected_county,
      });
    },
    cancel() {
      this.$emit("cancel");
    },
  },
};
</script>
<style lang="less">
#serverAdress {
  font-size: 1.4rem;
  box-sizing: border-box;
  width: 100%;
  .submitAdress {
    background: linear-gradient(to right, #ff6034, #ee0a24);
    height: 45px;
    font-size: 1.7rem;
    // font-weight: 700;
    color: #fff;
    text-align: center;
    line-height: 50px;
  }

  .selected {
    box-sizing: border-box;
    display: flex;
    align-items: center;
    height: 5rem;
    font-weight: 600;
    span {
      display: inline-block;
      padding: 1rem;
    }
  }
  .adressWrap {
    .iconResult {
      width: 2rem;
      height: 2rem;
    }
    box-sizing: border-box;
    width: 100%;
    height: 30rem;
    display: flex;
    justify-content: space-between;
    padding: 0 1rem;
    box-sizing: border-box;
    .leftWrap,
    .rightWrap {
      flex: 1;
      overflow: auto;
      .van-radio__label {
        padding-left: 3rem;
      }
    }
    .act {
      font-size: @theme-font-size-l;
      font-weight: 600;
    }
    .publicItem {
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 3.6rem;
      padding: 2rem 1.5rem;
      box-sizing: border-box;
    }
    .selectDistrict {
      padding: 1rem 0;
    }
  }
}
</style>