<template>
  <div id="personal-info">
    <van-nav-bar title="个人资料" :border="false" left-arrow @click-left="goLastPage">
    </van-nav-bar>
    <div class="topBac">
      <select-avatar @get="getAvatar" :iniUrl="submit_form.avatar" />
    </div>
    <van-tabs v-model="active">
      <van-tab title="主要信息(必填)">
        <div class="personal-info-wrap">
          <van-field clickable :value="value" label="服务区域" placeholder="点击选择服务区域" @click="service_areas_show = true" v-model="service_areas_ieldValue" is-link />
          <van-popup v-model="service_areas_show" position="bottom" :style="{ height: '70%' }">
            <select-address @get="getServiceAreas" @cancel="service_areas_show = false" :modelEmitObj="modelEmitObjAddress" />
          </van-popup>
          <van-field clickable :value="value" label="服务类目" placeholder="点击选择服务类目" @click="categories_show_area = true" v-model="categories_fieldValue" is-link />
          <van-popup v-model="categories_show_area" position="bottom" :style="{ height: '70%' }">
            <select-service-categories @get="getResultCategories" :emitObjServiceCategories="emitObjServiceCategories" />
          </van-popup>
          <van-field clickable :value="value" label="服务类型" placeholder="点击选择服务类型" @click="modes_show_area = true" v-model="modes_fieldValue" is-link />
          <van-popup v-model="modes_show_area" position="bottom" :style="{ height: '70%' }">
            <select-service-modes @get="getResultServiceModes" @cancel="modes_show_area = false" :emitObjServiceModel="modelEmitObjServiceModel" />

          </van-popup>

          <van-field clickable name="area" :value="value" label="所在地区" placeholder="点击选择省市区" @click="address_show_area = true" v-model="address_fieldValue" is-link />
          <van-popup v-model="address_show_area" position="bottom">
            <van-cascader v-model="submit_form.address_county_code" title="请选择所在地区" :options="all_address_list" :field-names="{text: 'name', value: 'id', children: 'childs'}" @finish="finishSelectAddress" />
          </van-popup>
          <van-field v-model="submit_form.address_detail" rows="1" autosize label="详细地址" type="textarea" placeholder="请输入详细所在地址" />
        </div>
      </van-tab>
      <van-tab title="其他信息">
        <div class="personal-info-wrap">
          <van-field v-model="submit_form.mobile" rows="1" autosize label="手机号" type="tel" disabled readonly />
          <van-field v-model="submit_form.name" rows="1" autosize label="姓名" type="name" disabled readonly />
          <!-- <van-field v-model="submit_form.certification" rows="1" autosize label="实名认证"  :is-link="submit_form.certification !='已实名认证'" :disabled="submit_form.certification =='已实名认证' " @click="goVerified" readonly/> -->
          <!-- {{verified_states.find(item => item.id == submit_form.certification).name}} -->
          <van-field v-if="submit_form.certification != undefined" label="实名认证" :value="verified_states.find(item => item.id == submit_form.certification).name" readonly is-link @click="goVerified" />
          <van-field v-model="submit_form.urgent_contact_mobile" rows="1" autosize label="紧急联系人" type="tel" placeholder="请输入紧急联系人号码" />
        </div>
        <div class="personal-info-wrap">

          <van-field clickable :value="value" label="工作日" placeholder="点击选择工作日" @click="working_day_show = true" v-model="working_day_fieldValue" is-link />
          <van-popup v-model="working_day_show" position="bottom" :style="{ height: '70%' }">
            <select-data @get="getWorkingDay" title="请选择工作日" :columns="weekDayColumns" />
          </van-popup>
          <van-field clickable :value="value" label="时间段" placeholder="点击选择时间段" @click="service_period_show = true" v-model="service_period_fieldValue" is-link />
          <van-popup v-model="service_period_show" position="bottom" :style="{ height: '70%' }">
            <select-data @get="getServicePeriod" title="请选择时间段" :columns="servicePeriodColumns" />
          </van-popup>
          <van-field v-model="submit_form.urgent_contact_mobile" rows="1" autosize label="紧急联系人" type="tel" placeholder="请输入紧急联系人号码" />
          <van-field v-model="submit_form.employee" rows="1" autosize label="团队人数" type="digit" placeholder="请输入团队人数" />
          <van-field v-model="submit_form.truck_number" rows="1" autosize label="汽车数量" type="digit" placeholder="请输入汽车数量" />
          <van-field clickable :value="value" label="汽车类型" placeholder="点击选择汽车类型" @click="truck_type_show = true" v-model="truck_type_fieldValue" is-link v-if="submit_form.truck_number >= 1" />
          <van-popup v-model="truck_type_show" position="bottom" :style="{ height: '70%' }">
            <select-data @get="getTruckType" title="请选择汽车类型" :columns="truck_type" />
          </van-popup>
        </div>
        <div class="personal-info-wrap">
          <van-field v-model="submit_form.pick_up_point" rows="1" autosize label="提货点地址" type="textarea" placeholder="提货点地址" />
          <van-field v-model="submit_form.description" rows="1" autosize label="优势简介" type="textarea" maxlength="50" show-word-limit placeholder="请填写优势简介" />
        </div>
      </van-tab>
    </van-tabs>

    <div style="width: 90%;margin: 5rem auto">
      <van-button round block type="info" native-type="submit" style="background: red; border: 1px solid red" @click="submitForm">确认修改</van-button>
    </div>
  </div>
</template>
<script>
import SelectServiceCategories from "@/components/Mine/select-tempalte/ServiceCategories.vue";
import SelectServiceModes from "@/components/Mine/select-tempalte/ServiceModes.vue";
import SelectAddress from "@/components/Mine/select-tempalte/SelectAddress.vue";
import SelectData from "@/components/Mine/select-tempalte/SelectData.vue";
import SelectAvatar from "@/components/Mine/select-tempalte/SelectAvatar.vue";

export default {
  data() {
    return {
      active: 2,
      weekDayColumns: [
        // 第一列
        {
          values: ["周一", "周二", "周三", "周四", "周五", "周六", "周日"],
          defaultIndex: 0,
        },
        // 第二列
        {
          values: ["周一", "周二", "周三", "周四", "周五", "周六", "周日"],
          defaultIndex: 4,
        },
      ],
      servicePeriodColumns: [
        // 第一列
        {
          values: [
            "00:00",
            "01:00",
            "02:00",
            "03:00",
            "04:00",
            "05:00",
            "06:00",
            "07:00",
            "08:00",
            "09:00",
            "10:00",
            "11:00",
            "12:00",
            "13:00",
            "14:00",
            "15:00",
            "16:00",
            "17:00",
            "18:00",
            "19:00",
            "20:00",
            "21:00",
            "22:00",
            "23:00",
            "24:00",
          ],
          defaultIndex: 7,
        },
        // 第二列
        {
          values: [
            "00:00",
            "01:00",
            "02:00",
            "03:00",
            "04:00",
            "05:00",
            "06:00",
            "07:00",
            "08:00",
            "09:00",
            "10:00",
            "11:00",
            "12:00",
            "13:00",
            "14:00",
            "15:00",
            "16:00",
            "17:00",
            "18:00",
            "19:00",
            "20:00",
            "21:00",
            "22:00",
            "23:00",
            "24:00",
          ],
          defaultIndex: 18,
        },
      ],
      truck_type: [
        {
          values: ["小型面包", "中型面包", "小型货车", "中型货车", "三轮车"],
          defaultIndex: 0,
        },
      ],
      value: "",
      address_show_area: false,
      categories_show_area: false,
      modes_show_area: false,
      service_areas_show: false,
      working_day_show: false,
      service_period_show: false,
      truck_type_show: false,
      areaList: {},
      all_address_list: [],
      address_fieldValue: "",
      categories_fieldValue: "",
      modes_fieldValue: "",
      service_areas_ieldValue: "",
      working_day_fieldValue: "",
      service_period_fieldValue: "",
      truck_type_fieldValue: "",
      submit_form: {
        address_county_code: undefined,
        address_detail: "",
        service_categories: undefined,
        service_modes: undefined,
        service_areas: undefined,
        working_day: undefined,
        service_period: undefined,
        urgent_contact_mobile: undefined,
        employee: undefined,
        truck_type: undefined,
        pick_up_point: undefined,
        description: undefined,
        avatar: undefined,
        receipt_address: undefined,
        truck_number: undefined,
        certification: undefined,
      },
      modelEmitObjAddress: {
        selected_province: undefined,
        selected_city: undefined,
        selected_county: [],
      },
      modelEmitObjService: {
        main_selected_service_type: [],
        additional_selected_service_type: [],
      },
      modelEmitObjServiceModel: [],
      emitObjServiceCategories: {
        main: [],
        additional: [],
      },
    };
  },
  components: {
    SelectServiceCategories,
    SelectServiceModes,
    SelectAddress,
    SelectData,
    SelectAvatar,
  },
  created() {
    this.getAllAddress();
    this.getIniFormData();
  },
  methods: {
    /**
     * 获取全国地址库
     */
    getAllAddress() {
      this.$http
        .get("https://cdn.yishifu.cn/static/json/addresses.json")
        .then((res) => {
          console.log(res, "总地址");
          this.all_address_list = res.data;
        });
    },
    getAvatar(e) {
      this.submit_form.avatar = e;
    },
    /**
     * 选择所在地址
     */
    finishSelectAddress({ selectedOptions }) {
      this.address_show_area = false;
      this.address_fieldValue = selectedOptions
        .map((option) => option.name)
        .join("/");
    },
    onConfirm(values) {
      this.value = values
        .filter((item) => !!item)
        .map((item) => item.name)
        .join("/");
      this.address_show_area = false;
    },
    getResultCategories(e) {
      console.log("获取到的服务类目", e);
      this.categories_show_area = false;
      this.categories_fieldValue = e.cn;
      this.submit_form.service_categories = e.main.join(",");
      this.submit_form.second_service_categories = e.additional.join(",");
    },
    getResultServiceModes(e) {
      console.log("获取到的服务类型", e);
      this.modes_show_area = false;
      this.submit_form.service_modes = e.id;
      this.modes_fieldValue = e.cn;
    },
    getServiceAreas(e) {
      console.log("获取到的服务区域", e);
      this.service_areas_show = false;
      this.submit_form.service_areas = e.id;
      this.service_areas_ieldValue = e.cn;
    },
    getWorkingDay(e) {
      console.log("获取工作日", e);
      this.working_day_show = false;
      this.submit_form.working_day = e.cn.join("至");
      this.working_day_fieldValue = e.cn.join("至");
    },
    getServicePeriod(e) {
      this.service_period_show = false;
      this.submit_form.service_period = e.cn.join("至");
      this.service_period_fieldValue = e.cn.join("至");
    },
    getTruckType(e) {
      this.truck_type_show = false;
      this.submit_form.truck_type = e.cn.join("");
      this.truck_type_fieldValue = e.cn.join();
    },
    goVerified() {
      switch (this.submit_form.certification) {
        case 0:
          this.$dialog.confirm({
            title: "实名认证",
            message: "实名认证审核中",
          });
          break;
        case 1:
          this.$router.push({
            name: "name_pass",
          });
          break;
        case 2:
          this.$dialog
            .confirm({
              title: "实名认证",
              message: "实名认证未通过",
              confirmButtonText: "重新认证",
            })
            .then(() => {
              this.$router.push({
                name: "certification",
              });
            })
            .catch(() => {});
          break;
        case -1:
          this.$dialog
            .confirm({
              title: "实名认证",
              message: "您已撤销审核，请重新认证",
              confirmButtonText: "重新认证",
            })
            .then(() => {
              this.$router.push({
                name: "certification",
              });
            })
            .catch(() => {});
          break;
      }
    },
    /**
     * 提交表单
     */
    submitForm() {
      let _f = this.submit_form;
      if (!_f.service_categories) {
        this.$toast("请填写服务类目");
      } else if (!_f.service_modes) {
        this.$toast("请填写服务类型");
      } else if (!_f.address_county_code) {
        this.$toast("请选择所在地区");
      } else if (!_f.address_detail) {
        this.$toast("请选择详细地址");
      } else if (!_f.service_areas) {
        this.$toast("请选择服务区域");
      } else {
        this.$http
          .post("api/v2/worker/profile", this.submit_form)
          .then((res) => {
            this.$toast.success(res.data.message);
            this.goSubPage("mine");
            console.log(res);
          })
          .catch((err) => {
            this.$toast.faled(err.error);
          });
      }
    },
    /**
     * 获取表单内容
     */
    getIniFormData() {
      this.$http.get("api/v2/worker/profile").then((res) => {
        console.log(res, "已填写表单信息渲染");
        let _ini_form_data = {};
        let _obj = res.data.profile;

        // 同步组件的地址
        this.modelEmitObjAddress = {
          selected_province:
            _obj.service_areas.length > 0
              ? _obj.service_areas[0].province_code * 1
              : undefined,
          selected_city:
            _obj.service_areas.length > 0
              ? _obj.service_areas[0].city_code * 1
              : undefined,
          selected_county: _obj.service_areas.map(
            (item) => item.county_code * 1
          ),
        };

        // 同步组件的服务类目
        this.emitObjServiceCategories = {
          main: _obj.service_categories.map((option) => option.id),
          additional: _obj.second_service_categories.map((option) => option.id),
        };

        // 同步组件的服务类型
        this.modelEmitObjServiceModel = _obj.service_modes.map(
          (option) => option.id
        );

        // 同步所在地址
        // this.address_fieldValue
        console.log(this.modelEmitObjServiceModel, "dsads");
        this.submit_form.address_detail = _obj.address_detail;
        this.submit_form.avatar = _obj.avatar;
        // 同步表面的页面文字
        this.address_fieldValue = _obj.full_address;
        this.categories_fieldValue =
          _obj.service_categories.map((option) => option.type_name).join(",") +
          _obj.second_service_categories
            .map((option) => option.type_name)
            .join(",");
        this.modes_fieldValue = _obj.service_modes
          .map((option) => option.type_name)
          .join(",");
        this.service_areas_fieldValue = "";
        this.working_day_fieldValue = "";
        this.service_period_fieldValue = "";
        this.truck_type_fieldValue = "";

        this.working_day_fieldValue = _obj.working_day;
        this.service_period_fieldValue = _obj.service_period;
        this.truck_type_fieldValue = _obj.truck_type;
        this.submit_form.service_areas = _obj;
        this.service_areas_ieldValue = _obj.service_areas
          .map((options) => options.county_name.name)
          .join(",");
        // 同步底层的数据
        (this.submit_form.address_county_code = _obj.address_county_code),
          (this.submit_form.address_detail = _obj.address_detail),
          (this.submit_form.service_categories = _obj.service_categories
            .map((option) => option.id)
            .join(","));
        this.submit_form.second_service_categories = _obj.second_service_categories
          .map((option) => option.id)
          .join(",");
        this.submit_form.service_modes = _obj.service_modes
          .map((option) => option.id)
          .join(",");
        (this.submit_form.service_areas = _obj.service_areas.map(
          (option) => option.county_code
        )),
          (this.submit_form.working_day = _obj.working_day),
          (this.submit_form.service_period = _obj.service_period),
          (this.submit_form.urgent_contact_mobile = _obj.urgent_contact_mobile),
          (this.submit_form.employee = _obj.employee),
          (this.submit_form.truck_type = _obj.truck_type),
          (this.submit_form.pick_up_point = _obj.pick_up_point),
          (this.submit_form.description = _obj.description),
          (this.submit_form.avatar = _obj.avatar),
          (this.submit_form.receipt_address = _obj.receipt_address),
          (this.submit_form.truck_number = _obj.truck_number);
        this.submit_form.name = _obj.name;
        this.submit_form.mobile = _obj.mobile;
        this.submit_form.certification = _obj.certification;

        console.log(this.modelEmitObjAddress, 9999);
      });
    },
  },
};
</script>

<style lang="less">
#personal-info {
  .topBac {
    width: 100%;
    height: 15rem;
    background: @themeColor;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .personal-info-wrap {
    margin: 1.5rem 1rem;
    background: #fff;
    overflow: hidden;
    border-radius: 8px;
  }
}
</style>