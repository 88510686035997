<template>
  <div id="service-modes">
    <tool-bar rightTitle="确定" title="选择服务类型" @cancel="cancel" @confirm="confirm" />
    <van-checkbox-group v-model="result">
      <van-cell-group>
        <van-cell v-for="(item,index) in service_modes_list" clickable :key="item.id" :title="item.type_name" @click="toggle(index)">
          <template #right-icon>
            <van-checkbox :name="item.id" ref="checkboxes" />
          </template>
        </van-cell>
      </van-cell-group>
    </van-checkbox-group>
  </div>
</template>

<script>
import ToolBar from "@/components/effect/ToolBar";

export default {
  props: {
    emitObjServiceModel: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  data() {
    return {
      service_modes_list: [],
      result: [],
    };
  },

  created() {
    // console.log(this.emitObjServiceModel);
    this.copyServiceModes()
    this.getServiceModesList();
  },
  components: {
    ToolBar,
  },
  methods: {
    cancel() {
      this.$emit("cancel", false);
    },
    copyServiceModes() {
      let _arr = []
      this.emitObjServiceModel.forEach(item => {
        _arr.push(item * 1)
      }) 
      this.result = _arr
    },
    getServiceModesList() {
      this.$http.get("api/common/serviceMode?id=1").then((res) => {
        this.service_modes_list = res.data;
        console.log("服务类型", res);
        console.log(this.emitObjServiceModel);
      });
    },
    toggle(index) {
      this.$refs.checkboxes[index].toggle();
    },
    confirm() {
      let _arr = [];
      this.service_modes_list.forEach((element) => {
        this.result.forEach((k) => {
          if (element.id == k) {_arr.push(element.type_name)};
        });
      });

      let result_obj = {
        cn: _arr.join(","),
        id: this.result,
        show: false,
      };
      this.$emit("get", result_obj);
    },
  },
};
</script>

<style lang="less">
#service-modes {
  .van-checkbox__icon--checked .van-icon {
    background-color: #ff7963;
    border-color: #ff7963;
  }
}
</style>