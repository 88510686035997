<template>
  <div class="select-avatar">
    <van-uploader :after-read="avatarUpLoad">
      <div class="avatarUpLoad" v-if="avatarLoading">
        <img :src="`${cdnHttp}${iniUrl}`" alt="" v-if="iniUrl">
        <img :src="`${MEDIA_URL.avatar}`" alt="" v-else />
        <!-- <span class="tip">1113</span> -->
      </div>
      <div class="avatarUpLoad" v-else>
        <van-loading type="spinner" />
      </div>
      <p class="tip">点击更改头像</p>
    </van-uploader>
  </div>
</template>
<script>
export default {
  props: {
    iniUrl: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      avatarLoading: true,
      avatarUrl: "http://static.yishifu.com/worker/avatar.png",
    };
  },
  methods: {
    // 请求上传头像
    avatarUpLoad(e) {
      console.log(e);
      let imageFormData = new FormData();
      imageFormData.append("files", e.file);
      // console.log(e, "ss");
      this.avatarLoading = false;
      this.$http
        .post("/api/worker/uploadAvatar", imageFormData, {
          headers: {
            "content-type": "multipart/form-data",
          },
        })
        .then((res) => {
          console.log(res);
          this.avatarLoading = true;
          this.avatarUrl = res.data.result.url;
          this.$emit("get", this.avatarUrl);
        });
    },
  },
};
</script>

<style lang="less">
.select-avatar {
  display: flex;
  justify-content: center;
  align-items: center;
  .avatarUpLoad {
    position: relative;
    overflow: hidden;
  }
  .tip {
    color: #fff;
    font-size: 1rem;
  }
  img {
    border: 2px solid #fff;
    border-radius: 1000rem;
    width: 7rem;
    height: 7rem;
  }
}
</style>

