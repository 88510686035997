<template>
  <div id="selectServiceType">
    <tool-bar rightTitle="确定" title="选择服务类目" @cancel="cancel" @confirm="confirm" />
    <van-collapse v-model="activeNames">
      <van-collapse-item title="主服务类目" name="1">
        <van-checkbox-group v-model="main_selected_service_type" @change="changeMainSelect">
          <div class="service_type_list">
            <van-checkbox v-for="item in main_service_type_list" :key="item.id" :name="item.id">
              <template #icon>
                {{item.type_name}}
              </template>
            </van-checkbox>
          </div>
        </van-checkbox-group>
      </van-collapse-item>
      <van-collapse-item title="附加服务类目" name="2">
        <van-tag type="primary" :style="{margin:'1rem 2rem 0 2rem'}" @click="toggleAll" size="large"> 全选</van-tag>
        <van-checkbox-group v-model="additional_selected_service_type" @change="changeAdditionalSelect" ref="checkboxGroup">
          <div class="service_type_list">
            <van-checkbox v-for="item in additional_service_type_list" :key="item.id" :name="item.id" :disabled="!!main_selected_service_type.find(k => k == item.id)">
              <template #icon>
                {{item.type_name}}
              </template>
            </van-checkbox>
          </div>
        </van-checkbox-group>
      </van-collapse-item>
    </van-collapse>
  </div>
</template>
<script>
import ToolBar from "@/components/effect/ToolBar";
export default {
  props: {
    emitObjServiceCategories: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      checked: false,
      main_selected_service_type: [],
      additional_selected_service_type: [],
      main_service_type_list: [],
      additional_service_type_list: [],
      activeNames: ["1", "2"],
      result_obj: {},
    };
  },
  components: {
    ToolBar,
  },
  created() {
    this.getServiceTypeList();
    setTimeout(() => {
      this.copyServiceCategories();
    }, 100);
  },
  methods: {
    /**
     *  服务类目接口获取
     */
    getServiceTypeList() {
      this.$http.get("api/common/firstCategory").then((res) => {
        // console.log("服务类目选择器列表", res.data);
        this.main_service_type_list = JSON.parse(JSON.stringify(res.data));
        this.additional_service_type_list = JSON.parse(
          JSON.stringify(res.data)
        );
      });
    },
    copyServiceCategories() {
      console.log(this.emitObjServiceCategories);
      this.main_selected_service_type = this.emitObjServiceCategories.main;
      this.additional_selected_service_type = this.emitObjServiceCategories.additional;
    },
    changeMainSelect() {
      if (this.main_selected_service_type.length > 1) {
        this.main_selected_service_type.length = 1;
        this.$toast("主类目只能选中一个");
      } else if (this.main_selected_service_type.length == 0) {
        this.additional_selected_service_type = [];
      }
      // console.log(this.main_selected_service_type);
    },
    changeAdditionalSelect() {
      // console.log(this.additional_selected_service_type);
      let selected_service_type_cn = [];
      this.main_service_type_list.forEach((item) => {
        this.main_selected_service_type
          .concat(this.additional_selected_service_type)
          .forEach((k) => {
            if (item.id == k) {
              selected_service_type_cn.push(item.type_name);
            }
          });
      });
      console.log(selected_service_type_cn, "oh");
      this.result_obj = {
        main: this.main_selected_service_type,
        additional: this.additional_selected_service_type,
        cn: selected_service_type_cn.join(","),
        all: this.main_selected_service_type.concat(
          this.additional_selected_service_type
        ),
      };
    },
    toggleAll() {
      this.$refs.checkboxGroup.toggleAll();
      this.main_selected_service_type.forEach((el) => {
        this.additional_selected_service_type.remove(el);
      });
    },
    cancel() {
      this.$emit("cancel", false);
    },
    confirm() {
      this.result_obj.show = false;
      this.$emit("get", this.result_obj);
    },
  },
};
</script>

<style lang="less">
#selectServiceType {
  .toggleAll {
    width: 6rem;
    height: 3rem;
    border: 1px solid @themeColor;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .van-collapse-item__content {
    padding: 0;
  }
  .service_type_list {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 1rem;
    .van-checkbox {
      // border: 1px solid #000;
      margin: 0.5rem;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      .van-checkbox__label {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
      }
      .van-checkbox__icon--round {
        width: 7.5rem;
        height: 3rem;
        border: 1px solid #000;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 1.3rem;
        .van-icon-success {
          display: none;
        }
      }

      .van-checkbox__icon--checked {
        border: 1px solid @themeColor;
        color: @themeColor;
        font-weight: 600;
      }
      .van-checkbox--disabled {
        cursor: not-allowed;
      }

      // .van-checkbox__icon {
      //   .van-icon {
      //     display: none;
      //   }
      // }
    }
  }
}
</style>